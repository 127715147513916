import React from "react"
import tw from "twin.macro"
import Header from "./header"
import Footer from "./footer"
import SEO from "../components/seo/seo"
const PageContainer = tw.div`
    bg-primary-200 text-xl w-1/2
`
const StyledDiv = tw.div`font-display min-h-screen text-secondary-600 p-8 overflow-hidden`
const HeaderBase = tw(Header)`max-w-none`

const Layout = ({ children }) => (
  <StyledDiv className="App">
    <SEO
      keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
      title="Home"
    />
    <HeaderBase />
    {children}
    <Footer />
  </StyledDiv>
)

export default Layout
