import React, { useState } from "react"
import Layout from "../layout/Layout"
import Testimonials from "../components/testimonials/TestimonialsComponent"
function TestimonialsPage() {
  return (
    <Layout>
      <Testimonials />
    </Layout>
  )
}

export default TestimonialsPage
